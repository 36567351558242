.notFound {
    height: 80vh;
    display: flex;
    flex-direction: column;
    justify-content: center;
}

.redUrl:hover {
    text-decoration: none;
    color: #000080;
    background-color: #DDF;
}