.aboutSec {
    min-height: 60vh;
}

.aboutInfo {
    text-align: justify;
}

.greetTitle {
    color: #8a2be2;
}

.paraTitle {
    color: #fff;
    border-radius: 2px 0 0 2px;
    background: linear-gradient(to right, rgba(138, 43, 226, 1), rgba(138, 43, 226, 0));
    border-left: 8px solid #8a2be2;
}

.specTitle {
    color: #8a2be2;
}

.alterImgSec img {
    width: 100%;
    border-radius: 8px;
}

.redUrl:hover {
    text-decoration: none;
    color: #000080;
    background-color: #DDF;
}

.userProfile {
    min-height: fit-content;
    position: sticky;
    top: 106px;
}