.blogCard {
    transition: 0.4s;
}

.blogCard:hover {
    box-shadow: 0 0 12px 2px rgba(0, 0, 0, 0.1);
    opacity: 0.85;
}

.blogCard:hover .extLinkIcon::after {
    font-family: "Font Awesome 5 Free";
    content: '\f08e';
    font-weight: 900;
}

.blogExtLink {
    text-decoration: none;
}