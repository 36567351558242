.banner {
    border-radius: 8px;
    background: linear-gradient(180deg, #e6e6e6, #ffffff);
    box-shadow: 20px 20px 60px #d9d9d9, -20px -20px 60px #ffffff;
}

.banner:hover {
    background: linear-gradient(90deg, #e6e6e6, #ffffff);
}

.logo img {
    width: 100%;
    border-radius: 8px;
}

.navLink {
    margin: 0;
    padding: 0;
}

.socialLink {
    border-radius: 6px;
    margin-right: 24px;
    background: linear-gradient(145deg, #e6e6e6, #ffffff);
    /* box-shadow: 10px 10px 20px #d9d9d9, -10px -10px 20px #ffffff; */
    box-shadow: 4px 8px 24px #d9d9d9, -4px -8px 24px #ffffff;
    transition: .4s;
}

.socialLink:hover {
    transform: translateY(-4px);
}

.socialIcon {
    color: #8a2be2;
    font-size: 28px;
    padding: 10px 18px;
    margin: 10px 0;
}

.socialIcon:hover {
    border-radius: 6px;
    color: #FFF;
    background: linear-gradient(145deg, #32309f, #3b39bd);
    box-shadow: 1px 1px 2px #2f2d96, outset -1px -1px 2px #3f3dcc;
}

.downloadLink a {
    text-decoration: none;
    border-radius: 8px;
    margin-right: 24px;
    background: linear-gradient(145deg, #32309f, #3b39bd);
    box-shadow: 20px 20px 60px #d9d9d9, -20px -20px 60px #ffffff;
}

.downloadIcon {
    border: 2px solid transparent;
    border-radius: 8px;
    color: #FFF;
    font-size: 20px;
    padding: 10px 18px;
    margin: 10px 0;
}

.downloadIcon:hover {
    border-radius: 8px;
    color: #8a2be2;
    background: linear-gradient(145deg, #e6e6e6, #ffffff);
    border-color: #8a2be2;
}