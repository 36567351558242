.projectCard {
    border-radius: 8px;
    background: linear-gradient(180deg, #e6e6e6, #ffffff);
    box-shadow: 20px 20px 60px #d9d9d9, -20px -20px 60px #ffffff;
    cursor: pointer;
    overflow: hidden;
}

.projectCard:hover {
    background: linear-gradient(90deg, #e6e6e6, #ffffff);
}

.projectCard:hover .thumbImg {
    transform: scale(1.1);
}

.thumbImg {
    transition: 0.4s;
    position: relative;
}

.tagBody {
    font-weight: 500;
    top: 26px;
    left: -50px;
    padding: 4px 44px;
    position: absolute;
    transform: rotate(-45deg);
    border-radius: 4px;
    background: linear-gradient(90deg, #9537ec, #8a2be2);
    box-shadow: 20px 20px 60px #d9d9d9, -20px -20px 60px #ffffff;
    backdrop-filter: blur(5px);
    -webkit-backdrop-filter: blur(5px);
}

.tagTitle {
    margin: 4px;
    color: #fff;
}