.App {
    /* text-align: center; */
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    /* background: url('../src/assets/images/bg.svg');
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  background-attachment: fixed; */
    user-select: none;
}

::-webkit-scrollbar {
    width: 12px;
}

::-webkit-scrollbar-track {
    border-radius: 12px;
    box-shadow: inset 0 0 24px rgba(138, 44, 226, 0.2);
}

::-webkit-scrollbar-thumb {
    border-radius: 12px;
    background-color: rgba(138, 44, 226, 0.7);
    /* box-shadow: 0 0 12px rgba(138, 44, 226, 0.7); */
}

::-webkit-scrollbar-thumb:hover {
    background-color: rgba(138, 44, 226, 1);
}
