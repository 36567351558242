.profileCard {
    max-width: 375px;
    height: fit-content;
    border-radius: 8px;
    background: linear-gradient(180deg, #e6e6e6, #ffffff);
    box-shadow: 20px 20px 60px #d9d9d9, -20px -20px 60px #ffffff;
    overflow: hidden;
}

/* .profileCard:hover {
    background: linear-gradient(120deg, #e6e6e6, #ffffff);
} */

.profileImg {
    margin: 0 auto;
    padding: 2px;
    width: 128px;
    height: auto;
    border: 3px solid #8a2be2;
    border-radius: 50%;
}

.skillItem {
    display: inline-block;
    margin: 4px;
    padding: 4px 8px;
    border: 2px solid #892be280;
    border-radius: 4px;
    transition: 0.5s;
}

.skillItem:hover {
    border-color: #8a2be2;
    transform: border;
}

/* .tagBody {
    font-weight: 500;
    top: 26px;
    left: -50px;
    padding: 4px 44px;
    position: absolute;
    transform: rotate(-45deg);
    border-radius: 4px;
    background: linear-gradient(90deg, #9537ec, #8a2be2);
    box-shadow: 20px 20px 60px #d9d9d9, -20px -20px 60px #ffffff;
    backdrop-filter: blur(5px);
    -webkit-backdrop-filter: blur(5px);
} */

/* .tagTitle {
    margin: 4px;
    color: #fff;
} */