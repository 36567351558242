.logoGlass {
    box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
}

.footerLink a {
    font-weight: 500;
    padding: 6px 0px 6px 12px;
    border-left: 3px solid rgba(137, 43, 226, 0.1);
    text-decoration: none;
    color: #8a2be2;
    text-transform: uppercase;
}

.footerLink a:hover {
    border-color: #8a2be2;
    background: linear-gradient(to right, #892be226, #ffffff);
}

.socialLink {
    border-radius: 6px;
    margin-right: 24px;
    background: linear-gradient(145deg, #e6e6e6, #ffffff);
    /* box-shadow: 20px 20px 60px #d9d9d9, -20px -20px 60px #ffffff; */
    box-shadow: 4px 8px 24px #d9d9d9, -4px -8px 24px #ffffff;
    transition: 0.4s;
}

.socialLink:hover {
    transform: translateY(-4px);
}

.socialIcon {
    color: #8a2be2;
    font-size: 28px;
    padding: 10px 18px;
    margin: 10px 0;
}

.socialIcon:hover {
    border-radius: 6px;
    color: #fff;
    background: linear-gradient(145deg, #32309f, #3b39bd);
    box-shadow: 1px 1px 2px #2f2d96, outset -1px -1px 2px #3f3dcc;
}

.redUrl:hover {
    text-decoration: none;
    color: #000080;
    background-color: #ddf;
}
