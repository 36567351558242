.btnExp {
    font-weight: 500;
    color: #8A2BE2;
    background: #FFF;
    border: 2px solid #8A2BE2;
}

.btnExp:hover {
    color: #FFF;
    background: #8A2BE2;
    border-color: transparent;
    /* transition: background 0.8s; */
}