.formArea {
    max-width: 560px;
}

.alertArea {
    margin: 0 auto;
    max-width: 560px;
}

.btnSendMsg {
    color: #FFF;
    background: #892be2cc;
}

.btnSendMsg:hover {
    background: #8a2be2;
    color: #FFF;
}