.modalGlass {
    max-width: 100%;
    background: rgba(255, 255, 255, 0.2);
    box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
    backdrop-filter: blur(6px);
    -webkit-backdrop-filter: blur(6px);
    /* border: 1px solid rgba(255, 255, 255, 0.3); */
}

.previewLink {
    display: flex;
    align-items: center;
    /* padding: 24px 16px; */
    border-radius: 4px;
    border: 1px solid transparent;
    color: #FFF;
    background: linear-gradient(145deg, #32309f, #3b39bd);
    box-shadow: 1px 1px 2px #2f2d96, inset -1px -1px 2px #3f3dcc;
    transition: 0.1s;
}

.previewLink:hover {
    border: 1px solid;
    background: linear-gradient(145deg, #e6e6e6, #ffffff);
    box-shadow: 20px 20px 60px #d9d9d9, -20px -20px 60px #ffffff;
}

.previewLink:focus {
    color: #FFF;
    background: linear-gradient(145deg, #32309f, #3b39bd);
}

.previewLink:active {
    border: 1px solid;
    background: linear-gradient(145deg, #e6e6e6, #ffffff);
    box-shadow: 20px 20px 60px #d9d9d9, -20px -20px 60px #ffffff;
}

.imgDiv::-webkit-scrollbar {
    /* display: none; */
    width: 4px;
}

.imgDiv::-webkit-scrollbar-track {
    border-radius: 4px;
    box-shadow: inset 0 0 20px rgba(138, 44, 226, 0.2);
}

.imgDiv::-webkit-scrollbar-thumb {
    border-radius: 4px;
    background-color: rgba(138, 44, 226, 0.7);
}

.imgDiv::-webkit-scrollbar-thumb:hover {
    background-color: rgba(138, 44, 226, 1);
}

.imgDiv {
    height: 150px;
    width: 100%;
    overflow-y: scroll;
}

.imgDiv img {
    width: 100%;
}

.techItem {
    font-size: 15px;
    display: inline-block;
    margin: 4px;
    padding: 4px 8px;
    border-radius: 4px;
    background-color: #e6e6e6;
}

.tagBody {
    padding: 4px 16px;
    border-radius: 3px 0px 0px 3px;
    background: linear-gradient(to right, rgba(138, 43, 226, 1), rgba(138, 43, 226, 0));
}

/* new */

.downloadLink a {
    text-decoration: none;
    border-radius: 8px;
    margin-right: 24px;
    background: linear-gradient(145deg, #32309f, #3b39bd);
    box-shadow: 20px 20px 60px #d9d9d9, -20px -20px 60px #ffffff;
}

.downloadIcon {
    border: 2px solid transparent;
    border-radius: 8px;
    color: #FFF;
    font-size: 20px;
    padding: 10px 18px;
    margin: 10px 0;
}

.downloadIcon:hover {
    border-radius: 8px;
    color: #8a2be2;
    background: linear-gradient(145deg, #e6e6e6, #ffffff);
    border-color: #8a2be2;
}