.navbar {
    padding: 0;
}

.navbarGlass {
    background: rgba(255, 255, 255, 0.7);
    box-shadow: 0 4px 30px rgba(0, 0, 0, 0.05);
    backdrop-filter: blur(5px);
    -webkit-backdrop-filter: blur(5px);
    /* border: 1px solid rgba(255, 255, 255, 0.3); */
}

.logoGlass {
    width: 48px;
    height: 48px;
    /* padding: 4px; */
    border-radius: 50%;
    border: 3px solid #8A2BE2;
}

.logoGlass,
.githubLogoGlass {
    box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
}

.navbarNav a {
    font-size: 18px;
    font-weight: 500;
    color: #2C3E50;
    text-decoration: none;
    padding: 8px 12px;
}

.navbarNav a:hover {
    color: #8A2BE2;
    /* background-color: #eee; */
    background: rgba(255, 255, 255, 0.2);
    border-radius: 4px;
    /* box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1); */
    backdrop-filter: blur(5px);
    -webkit-backdrop-filter: blur(5px);
}

.btnHire {
    font-weight: 500;
    color: #FFF;
    background: #8A2BE2;
    border: 2px solid rgba(255, 255, 255, 0.3);
    /* transition: 0.6s; */
}

.btnHire:hover {
    color: #8A2BE2;
    background: rgba(255, 255, 255, 0.2);
    backdrop-filter: blur(4px);
    border-color: #8A2BE2;
    transition: background 0.4s ease;
}

.specNav a {
    text-decoration: none;
    color: #ffffff;
}

.specNav a:hover {
    color: #8A2BE2;
    background: rgba(255, 255, 255, 0.2);
    backdrop-filter: blur(4px);
    border-color: #8A2BE2;
    transition: background 0.4s ease;
}