.newVersionGlass {
    background: rgba(255, 255, 255, 0.7);
    box-shadow: 0 4px 30px rgba(0, 0, 0, 0.05);
    backdrop-filter: blur(5px);
    -webkit-backdrop-filter: blur(5px);
    border-bottom: 4px solid rgba(138, 43, 226, 0.4);
}

.redUrl:hover {
    text-decoration: none;
    color: #000080;
    background-color: #DDF;
}