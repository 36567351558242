.devSkill {
    color: #333;
    padding: 30px 20px;
    display: flex;
    align-items: center;
    border: 2px solid whitesmoke;
    border-radius: 6px;

    background: linear-gradient(45deg, #e6e6e6, #ffffff);
    box-shadow: 20px 20px 60px #d9d9d9, -20px -20px 60px #ffffff;
    transition: 0.4s;
}

.devSkill:hover {
    border: 2px solid;
    color: #8a2be2;
    background: linear-gradient(180deg, #e6e6e6, #ffffff);
    transform: translateY(-4px);
}